@import "../../scss/variable";
.hint{
    position: absolute;
    margin-top: 6px;
}

.form-feedback {
    position: "absolute";
    margin-top: 1px ;
}

.field-style {
    background: $color-white;
    border: 1px solid $color-white-light-gray;
    height: 40px;
}

.outline-field-style {
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent !important;
    border-bottom: 1px solid $color-white-light-gray;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}