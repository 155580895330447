@import "../_variable.scss";

@media only screen and (min-width: 350px) {
    .campaign-list-page {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
.campaignDetail {
  min-height: 70vh;
}

.programCard {
  display: table;
}

.programCard-avatar {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10;
}

.programCard-email-section {
  display: table-cell;
  vertical-align: middle;
}
.campaign-details-subtitle {
  color: #1b3165;
  font-weight: 400;
  font-size: $sub-heading-font-size;
  line-height: 18px;
}

.campaign-details-added {
  font-weight: 600;
  font-size: $text-font-size;
  color: $color-green;
  line-height: "20px";
}
.campaign {
  padding: 0.275rem, 0.1rem;
  .icon {
    fill: $color-input-gray-dark;
    width: 14px;
    height: 14px;
  }
}