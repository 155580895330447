.link-width {
    width: 15px;
}

.avatar-padding-right {
    padding-right: 10px;
}

.profile-name {
    @media (max-width: 575px) {
        display: none;
    }
}
