
@import "../../scss/variable";

.tag-style {
    cursor: pointer;
    padding: 5px;
    border: 1px solid $color-dark-gray1;
    border-radius: 2px;
    font-weight: 600;
    margin-block: 5px;
}