@import "../../scss/_variable.scss";

.save {
    border-radius: 4px;
    min-width: 90px;
    font-size: $cancel-button-font-size;
    line-height: 1.25rem;
    padding: ".5rem 1.25re " ;
    background-color: #0078D4 !important;
    outline: 0px;
    box-shadow: none !important;
}

.label {
    margin-bottom: 5px;
}