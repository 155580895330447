.password {
    background: #FFF !important;
    border: 1px solid #CCCCCC !important;
    height: 40px !important;
}

.eye-icon {
    position: absolute;
    bottom: 12px;
    right: 16px;
  }