
.tag{
    padding: 3px 5px;
    border: 1px solid #707B96;
    border-radius: 2px;
    color: #FFF;
    background-color: #707B96;
    font-weight: 600;
    margin-block: 5px;
    flex-basis: fit-content;
    line-height: 13px;
}

.selected-tags{
    flex-basis: "fit-content";
    margin-bottom: .5rem;
}