@import "../../scss/_variable.scss";

.cancel-button{
    background-color: #FFF !important;
    border-radius: 3px;
    width:90px;
    font-size: $cancel-button-font-size;
    font-weight: 600;
    box-shadow: none  !important;

}
