@import "../_variable.scss";

.sidebar-content-box {
    padding-top: 1rem;
    width: 15rem;
    height: 100vh;
  }


.sidebar-action-button {
    background-color: red;
    display: flex;
    color: #fff;
    border-left: #fff;
    align-items: center;
    gap: 3px;
    border: 1px solid;
    text-align: left;
    padding: 10px;
    font-size: $label-font-size;
  }
  .sidebar-card {
    padding: 1px;
    height: 20px;
    align-items: center;
  }