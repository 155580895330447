@import "../../scss/variable";
.number-field {
    width: 45px;
    height: 33px;
    background: $color-white-smoke;
    border: 1px solid $color-light-gery4;
    outline: 0;
    border-radius: 3px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}