.setting-icons {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  
  @media only screen and (min-width: 350px) and (max-width: 990px) {
    .setting-section {
      max-height: 130px;
    }
  }
  .setting-page:first-child {
    border-left: 1px solid #d2d2d2 !important;
  }
  .setting-page:nth-child(2) {
    border-right: 1px solid #d2d2d2 !important;
  }