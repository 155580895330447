
.drop-down {
    width: 50px !important;
    text-align: center;
}


@media only screen and (min-width: 1400px){
    .drop-down {
        width: 60px !important;
    }
}

@media only screen and (min-width: 1600px) {
    .drop-down {
        width: 90px !important;
    }
}

@media only screen and (min-width: 1900px) and (max-width: 2000px) {
    .drop-down {
        width: 120px !important;
    }
}

@media only screen and (min-width: 2000px) {
    .drop-down {
        width: 150px !important;
    }
}