.nav-link {
  cursor: pointer;
  color: #fff !important;
  margin-left: 1rem;
  width: 300px;
}

.nav-tabs {
  border-bottom: 0 !important;
  span {
    color: black;
  }
  svg {
    path {
      fill: #282268 !important;
    }
  }
}

// nav tabs
.admin-tabs {
  &.nav-tabs {
    border-bottom: 2px solid $tab-inactive-border;
    .nav-item {
      margin-bottom: -2px;
      .nav-link {
        border-width: 0 0 2px;
        border-color: transparent;
        border-style: solid;
        border-radius: 0;
        padding: 0.875rem 1.25rem;
        cursor: pointer;
        color: rgba(41, 41, 52, 0.5);
        &.active {
          border-bottom: 4px solid;
          border-bottom-color: #707b96;
          background-color: transparent;
          color: $color-dark;
        }
      }
    }
  }
}

#nav-dropdown > * {
  margin: 0 0 16px 0;
}

.navbar {
  width: 100%;
}

.left-nav-bg {
  height: 100%;
  position: absolute;
  z-index: -1;
}

.nav-right-arrow {
  @media (min-width: 576px) and (max-width: 990px) {
    display: none;
  }
}

// header dropdown
.navbar {
  .dropdown-wrapper {
    .edit-profile-item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: flex-start;
    }
    .dropdown-menu {
      min-width: 265px;
    }
    .dropdown {
      .edit-profile-actions {
        margin-left: 1.25rem;
      }
    }
  }
}
