@import "../../scss/_variable.scss";

.icon {
 margin-left: 8px !important; 
 font-size: $small-font-size !important;
 font-weight: 600 !important; 
}

.add-button{
    box-shadow: none  !important;
}