@import "../../../scss/variable";
@import "../../../scss/mixins";

#sidebar {
  border-right: 1px solid #e2e2e8;
  color: lightgray;
  min-height: calc(100vh - 61px); // minus the navbar height
  height: 100%;
  position: relative;
  padding-top: 1.5rem;

  .primary-sidebar {
    border-right: 1px solid #e2e2e8;
    color: lightgray;
    min-height: calc(100vh - 61px); // minus the navbar height
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    position: relative;
    padding-top: 1.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 200px;
  }

  @media only screen and (max-width: 900px) and (min-width: 700px) {
    width: 66px !important; 
  }

  .sidebar-menu {
    @media screen and (min-width: 991px) {
      float: left;
    }
    margin-top: 10px;
  }

  .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
  }

  ul {
    // border-bottom: 1px solid $color-background-gray;

    p {
      color: #292934;
      margin-top: 10px;
    }

    li {
      border-left: 3px solid transparent;
      transition: all 180ms ease;
      &.active {
        background-color: #fff;
        border-left: 3px solid $color-cerulean;
        svg {
          color: #B6B6B6;
          path {
            fill: currentColor !important;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.8125rem 0.875rem;
        font-size: $login-button-font-size;
        color: #1B3165;
        text-decoration: none;
      }
      svg {
        max-height: 22px;
        margin: 0 1rem 0 0;
        min-width: 24px;
        @include tablet {
          margin-right: 0;
        }
      }
      &:hover {
        background-color: #fff;
        border-left: 3px solid #3572b0;
        color: #000;
        .sidebar-menu {
          color: #2496cd;
        }
      }
    }
  }
}

a[aria-expanded="true"] {
  color: #292934;
}

@include tablet {
  .sidebar-wrapper {
    &.w-auto {
      padding-left: 0;
      max-width: 71px;
      width: 200px !important;
    }
  }
}
@include mobile-xs {
  .sidebar-wrapper {
    position: fixed;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0 !important;
    &.w-auto {
      padding-right: 0 !important;
      border-right: 0;
      max-width: none;
      width: 200px !important;
    }
    
    &.menu-hidden {
      display: none;
    }
    &.menu-toggle {
      display: block;
    }
   
    #sidebar {
      width: 100vw;
      background-color: $theme-color-body-background;
      border-right: 0;
      min-height: calc(100vh - 46px);
    }
    ul {
      li {
        svg {
          margin-right: 1rem !important;
        }
      }
      .desktop-only {
        display: block;
      }
    }
    #sidebar {
      padding-top: 1rem;
      position: absolute;
      overflow-y: scroll;
    }
  }
}
.site-sidebar {
  margin-bottom: 2.5rem;
  &.checkout-sidebar {
    margin-top: 115px !important;
  }

  @media screen and (min-width: 500px) {
    background: none !important;
  }

  @include mobile {
    margin-bottom: 0;
    .card {
      max-width: none;
    }
  }
}
.sidebar-content-box {
  &:not(.transparent-box) {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: $color-white;
  }
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
  &.transparent-box {
    .sidebar-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include mobile {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
.sidebar-content {
  padding: 1.25rem;
  .title {
    margin-bottom: 0.25rem;
  }
  .subtitle {
    opacity: 0.5;
    margin-bottom: 0.5rem;
  }
  .price {
    margin-bottom: 1rem;
  }
  p {
    font-size: $cancel-button-font-size;
    &:not(:last-of-type) {
      margin-bottom: 0.25rem;
    }
    svg {
      margin: 0 0.5rem 0 0;
      width: 18px;
    }
  }
  .checklist {
    li {
      display: flex;
          font-size: $cancel-button-font-size;

      svg {
        margin-right: 0.625rem;
        width: 20px;
        color: $color-orange;
      }
    }
  }
  .badge {
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-weight: 300;
    margin: 0 0.5rem 0.5rem 0;
    float: left;
  }
  .btn-xs {
        font-size: $cancel-button-font-size;

  }
}