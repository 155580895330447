@import "../components/sidebarNavigation/SideBar/Style.scss";

@import "./pages/Login.scss";
@import "./pages/Signup.scss";
@import "./pages/Resetpassword.scss";
@import "./pages/Nav.scss";
@import "./pages/Sidebar.scss";

@import "./pages/Contact.scss";
@import "./pages/Content.scss";
@import "./pages/Campaign.scss";
@import "./pages/Setting.scss";
@import "../scss/_variable.scss";

h6 {
  font-weight: 700;
  margin-bottom: 0;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.social-icons {
  width: 25px;
  height: 22px;
  margin: 0 4px;
}

.select-file {
  color: #009dda;
  cursor: pointer;
  text-decoration: underline;
}

/* Background colors */
.bg-grey {
  background: #f9f9f9;
}

.hover-link {
  color: #707b96;
}

.hover-link:hover {
  color: #1e97f3;
}

//Only Desktop Media Query
@media (min-width: 1200px) {
  .sidebar-action {
    width: 350px !important;
  }
  .sidebar-menu-toggle {
    width: 300px;
  }
}

.sidebar-action {
  height: 83vh;
}

.tags {
  border-color: "#707B96";
}

// Campaign Section

.sectionTitle-Label {
  cursor: pointer;
  color: #0078d4;
}

.sidebar-img {
  width: 7px;
  height: 12px;
}

// Content section

// Contact Section

.badges {
  .input-group {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    > .input-group-prepend {
      > .input-group-text {
        padding: 0.25rem 0.5rem;
        svg {
          width: 1rem;
        }
      }
    }
  }
}

.input-group {
  > .badge {
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-start;
    margin: 0;
    min-width: 0;
    position: relative;
    display: none;

    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.group-dark {
    .input-group-text {
      background-color: $color-dark;
      border-color: $input-group-text-border;
      color: $color-white;

      svg {
        color: currentColor;
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .validation {
    .invalid-feedback {
      position: absolute;
    }
  }
}

@media screen and (min-width: 767px) {
  .check {
    .invalid-feedback {
      position: absolute;
    }
  }
}

.accepted-terms {
  float: left;
  padding-bottom: 15px;

  span {
    color: $color-light-black;
    float: left;
    font-size: $span-font-size;
  }

  input {
    &[type="checkbox"] {
      cursor: pointer;
      float: left;
      position: relative;

      &::before {
        background-color: $color-white;
        border: 1px solid $color-light-grey2;
        border-radius: 3px;
        display: block;
        height: 13px;
        left: 0;
        position: absolute;
        top: 0;
        width: 13px;
      }

      &:checked {
        &::after {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border: solid $color-black;
          border-width: 0 2px 2px 0;
          display: block;
          height: 12px;
          position: absolute;
          transform: rotate(45deg);
          width: 5px;
        }
      }
    }
  }

  .invalid-feedback {
    display: block;
    margin-top: 20px;
  }
}

@media (max-width: 620px) {
  .header-search {
    display: none;
  }
}

@media (max-width: 650px) {
  .header-search {
    width: 280px;
  }
}

.social-icons {
  width: 25px;
  height: 22px;
  margin: 0 7px;
}

.footer-icons {
  float: right;
  @media (max-width: 575px) {
    float: none;
    text-align: center;
    margin-top: 10px;
  }
}

.footer-text {
  float: left;
  @media (max-width: 575px) {
    float: none;
    text-align: center;
  }
}

/* Sidebar highlighted color in setting page */
.activeNav {
  background: #eff1f5;
}

/* 
   radio button css 
*/
input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 0 2px #0078d4;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #0078d4;
}

.exclamation-icon {
  width: 38px !important;
  height: 43px;
  margin: -25px !important;
}

// dashboard site maincontent styles
.site-maincontent {
  padding-top: 1.5rem;
  @include mobile-xs {
    padding-top: 1rem;
  }
}

// admin action nav
.admin-action-navigation {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $tab-inactive-border;
  a {
    font-size: $cancel-button-font-size;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  + .page-title {
    margin-top: 2rem !important;
  }
  .steps-wrapper {
    .step {
      .step-status-text {
        font-size: $cancel-button-font-size;
      }
    }
  }
  .project-name {
    max-width: 320px;
  }
  @include mobile-xs {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

@include mobile-xs {
  .with-hamburger-menu {
    display: flex;
    justify-content: stretch;
    .admin-action-navigation {
      flex-grow: 1;
    }
  }
}

// global page heading related styles
.page-heading {
  margin-bottom: 1.5rem;
  .btn-wrapper {
    .btn {
      white-space: nowrap;
      font-size: $login-button-font-size;
      padding-left: 0.5rem;
      height: 100%;
      padding-right: 1rem;
      svg {
        margin-bottom: 0;
      }
      span {
        font-size: $cancel-button-font-size;
      }
    }
  }
  .page-search {
    padding-right: 1rem;
    @media (max-width: 768px) {
      padding-right: 0rem;
      margin-bottom: 1rem;
    }
    max-width: 100%;
    width: auto;
    &.input-group > {
      .input-group-prepend {
        &:not(:first-child) > {
          .input-group-text {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }
        }
      }
    }
  }
  &.cover {
    background: #fff !important;
    padding: 1.25rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    .form-control {
      &:focus {
        background-color: #e7e7ea !important;
        border: 1px solid #e7e7ea;
        border-radius: 0;
      }
    }
  }
  @include tablet {
    .page-search,
    .dropdown-wrapper {
      p {
        display: none;
      }
    }
  }
  @include mobile {
    .page-search,
    .input-group > {
      .input-group-text {
        display: none;
      }
    }
    .dropdown-wrapper {
      &.select-dropdown {
        p {
          display: none !important;
        }
        min-width: 100%;
        justify-content: start !important;
        .btn-group {
          width: 100%;
          .dropdown-toggle {
            position: relative;
            svg {
              position: absolute;
              right: 5px;
              top: auto;
            }
          }
        }
      }
    }
  }
  @include mobile-xs {
    .page-search,
    .dropdown-wrapper {
      p {
        display: none !important;
      }
    }
    .page-search {
      width: 100%;
      .input-group-prepend {
        display: none;
      }
      &.input-group > {
        .form-control {
          border-radius: 0;
        }
      }
    }
  }
}

.global-search {
  max-width: 55% !important;
}
.hamburger-btn {
  position: relative;
  z-index: 10;
  display: none !important;
  margin-right: 3px;
  @include mobile-xs {
    display: block !important;
  }
}

// global page search component
.page-search {
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  max-width: 100%;
  .input-group-text {
    background-color: #f9f9f9;
    svg {
      fill: $color-input-gray-dark;
    }
  }
  .campaign {
    padding: 0.275rem, 0.1rem;
    .icon {
      fill: $color-input-gray-dark;
      width: 14px;
      height: 14px;
    }
  }
  .setting-page:first-child {
    border-left: 1px solid #d2d2d2 !important;
  }
  .setting-page:nth-child(2) {
    border-right: 1px solid #d2d2d2 !important;
  }
  input {
    background-color: #f9f9f9;
    outline: none !important;
    line-height: 2.5;
    &::placeholder {
      color: #a8a8a8;
    }
  }

  .input-group-text,
  .form-control {
    font-size: $sub-heading-font-size;
    border: none;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
  }
}

// main-content styles for public pages
.content {
  margin-top: 2rem;
  p {
    &:not(:last-child) {
      margin-bottom: 1.75rem;
    }
    img {
      float: left;
      max-width: 270px;
      margin: 0 1.75rem 1rem 0;
    }
    + ul {
      margin-top: -0.75rem;
      + ul {
        margin-top: auto;
      }
    }
  }
  ul {
    padding-left: 0;
    list-style-position: inside;
  }
}

// cart page related
.cart-item {
  margin-bottom: 1.5rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  p {
    &.h6 {
      font-size: $login-button-font-size;
    }
    b + form {
      margin-top: 0.5rem;
    }
  }
  form {
    .btn-secondary {
      padding: 0.5rem 0.675rem;
    }
  }
}

// global dropdowns?
.dropdown-wrapper {
  .dropdown-menu {
    border-radius: 0;
    border: none;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 4;
  }
  .dropdown-item {
    margin: 0.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:focus {
      outline: none;
    }
  }
  .dropdown-item,
  .dropdown-item:active,
  .dropdown-item.active {
    background-color: transparent;
    box-shadow: none;
    color: inherit;
  }
  .btn-group {
    #caret + .dropdown-toggle {
      &:after {
        content: none;
      }
    }
    .dropdown-toggle {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &.select-dropdown {
    display: inline-flex;
    .dropdown-toggle {
      display: flex;
      align-items: center;
      svg {
        margin: 0 0 0 0.5rem;
        max-width: 15px;
      }
    }
  }
  .dropdown-menu {
    width: auto;
  }
  .hide-caret {
    &:after {
      visibility: hidden;
    }
  }
}

// more dropdown from cards .. etc
.more-dropdown {
  .btn {
    &.btn-secondary {
      background-color: transparent;
      padding: 0.375rem;
      box-shadow: none !important;
      svg {
        color: $color-white;
        fill: #352a2a34;
      }
      &:not(:disabled) {
        &:focus,
        &:active,
        &.focus,
        &.active {
          background-color: transparent;
        }
      }
    }
  }
}

.more-dropdown {
  .fill-black {
    .btn {
      &.btn-secondary {
        svg {
          fill: #000000 !important;
        }
      }
    }
  }
}

.dropdown {
  .dropdown-inline-item {
    padding: 0.5rem 1.5rem;
  }
}

#partner-manager {
  .dropdown-toggle {
    width: 85%;
  }
  .dropdown-menu {
    width: 85%;
  }
}

.user-dropdown {
  .dropdown-toggle {
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
    width: 100%;
    font-size: $cancel-button-font-size;
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
  .user-badge {
    padding: 0;
    background-color: transparent;
    height: auto;
    margin-right: 1rem;
    font-size: $cancel-button-font-size;

    img {
      width: 30px;
      height: 30px;
      margin-right: 0.625rem;
    }
  }
  .no-assignee {
    padding-left: 0.5rem;
  }
  .dropdown-menu {
    width: 100%;
  }
  .dropdown-item,
  .dropdown-toggle {
    padding: 0.25rem;
    display: flex;
  }
  .dropdown-menu {
    .dropdown-item {
      &.active-selection {
        background-color: #d9f1fa;
      }
    }
  }
}
.action-group-dropdown {
  .more-dropdown {
    .btn {
      &.btn-secondary {
        svg {
          fill: $color-light-grey2 !important;
        }
      }
    }
  }

  .show {
    width: auto;
  }
}
.content-title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15vw;
}

.date-picker {
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  font-size: $login-button-font-size;
  padding: 0.375rem 0.75rem;
  appearance: none;
  opacity: 1;
  background-color: transparent;
}

@media (min-width: 300px) {
  .contactlist-checkbox {
    max-width: 25px !important;
  }
  .contactlist-avatar {
    max-width: 55px !important;
  }
}

@media (max-width: 1200px) {
  .contactlist-avatar {
    margin-top: 8px !important;
    max-width: 75px !important;
  }
}

.page-title-button {
  font-size: $button-font-size !important;
}

@media screen and (min-width: 350px) {
  .contact-name {
    text-indent: 27%;
  }
  .user-list-name {
    text-indent: 37%;
  }
}
@media screen and (min-width: 1900px) {
  .contact-name {
    text-indent: 10%;
  }
}

.action-button-section {
  background: $color-true-blue !important;
  outline: 0;
  border: none !important;
  height: 50px;
  border-radius: 0 !important;
  border-right: 1px solid $light-grey-color !important;
  box-shadow: none;
}

.save-button {
  background: $primary-button-color !important;
  color: $color-white;
  width: 100%;
  height: 50px;
  border-radius: none !important;
  border: none;
}

.discard-button {
  color: $text-primary-color !important;
  width: 50%;
  border-radius: none !important;
  margin-top: 4px;
  font-size: 12px !important;
}

.top-nav-text {
  font-weight: 500;
  line-height: 12px;
  color: $text-primary-color !important;
  margin-left: 5px;
}

@media screen and (mix-width: 300px) {
  .userlist-name {
    max-width: 90px !important;
  }
  .userlist-checkbox {
    max-width: 10px !important;
  }
  .userlist-avatar {
    max-width: 25px !important;
  }
}

.assigncontact-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-drop-down {
  transform: translate(0px, 35px) !important;
}

.assign-contact-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15vw !important;
}

@media screen and (max-width: 450px) {
  .assign-contact-card-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max(15vw, 220px) !important;
  }
}
