@import "../_variable.scss";

@media only screen and (min-width: 1200px) {
  .content-link {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .content-file-selector {
    position: absolute;
    left: calc(100% - 150px);
    z-index: 3;
  }
}

.contentCard {
  border-radius: "5px";
  border: "1px solid #D2D2D2";
  margin-bottom: ".5rem";
}

.contentCard-img {
  padding-right: 10px;
}

.contentCard-img-section {
  height: 44px;
}

.contentCard-img-img {
  width: 20px;
  height: 16px;
}

.contentCard-data {
  vertical-align: middle;
}

.contentCard-data-subject {
  margin: "15px 0 0";
}

.contentCard-drop {
  border-radius: 5px;
  border: 1px dashed #707b96;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.contentCard-drop-content {
  line-height: 18px;
}

.detailCard-img {
  width: 9px;
  height: 11px;
  margin-right: 3;
}

.dragContent {
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  margin-bottom: 0.5rem;
}

.dragContent-section {
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15vw;
}

.dragContent-section-data {
  padding-right: 10px;
}

.dragContent-section-img {
  width: 20px;
  height: 16px;
}

.dragContent-datasubject {
  vertical-align: middle;
}

.dragContent-data-subject {
  margin: 15px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentCard-section {
  display: table;
}

.contentCard-section-img {
  width: 20px;
  height: 16px;
}

.contentCard-section-title {
  display: table-cell;
  vertical-align: middle;
}

.contentCard-section-item {
  padding: 3px 5px;
}

.contentPreview-section {
  color: #707b96;
  font-size: $span-font-size;
  line-height: 20px;
  font-weight: 700;
}

.contentPreview-subject {
  color: #1b3165;
  font-size: $section-font-size;
  font-weight: 800;
}

.contentPreview-body {
  color: #707b96;
  font-size: $span-font-size;
  font-weight: 700;
}

.contentPreview-eye {
  margin-top: 300px;
  margin-left: 120px;
  font-size: $section-font-size;
}

.contentPreview-upload {
  font-size: $section-font-size;
  margin-top: 20px;
}

.addContent {
  border-bottom: 1px solid #cccccc;
}

.contentDetail-img {
  width: 11px;
  height: 13px;
}

.contentForm {
  font-size: $sub-heading-font-size;
  color: white;
  height: 35px;
  width: max-content;
  background-color: #0078d4;
}

// Only Desktop Media Query
@media (min-width: 1501px) {
  .addContentHeader {
    width: 350px !important;
  }
  .addContentHeader {
    height: 7vh;
  }
}

// Only Desktop Media Query
@media (max-width: 1500px) {
  .addContentHeader {
    width: 350px !important;
  }
  .addContentHeader {
    height: 9vh;
  }
}
.content-tags {
  padding: 3px 5px;
  border: 1px solid #707b96;
  border-radius: 2px;
  font-weight: 600;
  margin-block: 5px;
  cursor: pointer;
  flex-basis: fitContent;
}
.sidebar-content {
  .cart-item {
    padding-bottom: 0.75rem;
    margin-bottom: 0.875rem;
    > p,
    > ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-of-type {
      border: none;
    }
  }
}


@media (max-width: 992px) {
  .contentPreview-eye {
    margin-top: 100px;
    margin-left: 20px;
    text-align: center;
    margin-bottom: 100px;
  }
}