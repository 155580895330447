.breadcrumb-background {
    background-color: #ECECEC;
    margin-left: -20px;
    margin-right: -20px;
    height: 25px;
}

.breadcrumb-pointer {
    cursor: pointer;
    color: #1B3165;
}
.breadcrumb-list {
    color: #1B3165;
}