@import "../_variable.scss";

.assignContact {
  border-block: ".5px solid #CCCCCC";
}

.assignContact-checkbox {
  height: 70px;
}

.assignContact-avatar {
  padding-right: 5px;
  padding-left: 5px;
}

.contactList {
  margin-bottom: 50px;
  overflow-y: auto;
}

.contactList-checkbox {
  height: 66px;
}

.contactList-name {
  display: table-cell;
  vertical-align: middle;
}

.contactList-firstname {
  font-size: $description-font-size;
  margin: "5px 0 0";
  font-weight: 700;
}

.contactList-email {
  font-size: $title-font-size;
  width: 200px;
  margin: 0;
  padding: 0;
}

.ContactList {
  margin-left: -13px;
  margin-right: -13px;
}

.contactInformation-section {
  background-color: #fff;
}

.generalInformation-section {
  padding-left: 0.5rem;
  font-weight: 800;
  font-size: $heading-font-size;
  line-height: 20px;
  color: #707b96;
}

.generalInformation-added {
  line-height: 20px;
}

.correspondenceHistory {
  padding: 5px;
}

.correspondenceHistory-section {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.correspondenceHistory-data {
  border: 1px solid #707b96;
  border-radius: 3px;
  padding: 4px;
  font-size: $subtitle-font-size;
}

.actionButton-section {
  max-width: max-content;
}

@media only screen and (min-width: 1200px) and (max-width: 1380px) {
  .actionButton-section {
    max-width: 10px;
  }
}

.actionButton-img {
  width: 18px;
  height: 18px;
}

.actionButton-Assign {
  font-size: $sub-heading-font-size;
  font-weight: 500;
  line-height: 12px;
  margin-right: 0 50 0 0;
}

.actionButton-Edit {
  font-size: $sub-heading-font-size;
  font-weight: 500;
  line-height: 12px;
}

.actionButton-img-trash {
  width: 14px;
  height: 18px;
}

.assignToCampaign {
  border-block: 0.5px solid #cccccc;
}

.assignToCampaign-section {
  height: 70px;
}

.assignToCampaign-data {
  display: table-caption;
  vertical-align: middle;
}

.assignToCampaign-data-name {
  font-size: $description-font-size;
  margin: 5px 0 0;
}

.assignToCampaign-data-contactList {
  font-size: $title-font-size;
  width: 200px;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 500px) {
  .contact-list-page {
    display: flex;
    flex-wrap: wrap;
  }
}

/* 
    contact card - style applied for contact card, campaing card, content card
*/
.contact-card {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.contact-link {
  margin-left: -20px;
  margin-top: 15px;
}
