@import "../_variable.scss";

.login-btn {
    font-weight: 600;
    color: #fff;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: $login-button-font-size;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .login-btn:hover {
    opacity: 0.9;
    transform: scale(1.005);
  }
  
  .client-login-wrapper {
    width: 360px;
    margin: 0 auto;
  }
  
  @media only screen and (max-width: 500px) {
    .client-login-wrapper {
      margin: 0 auto;
    }
  }
  