@import "../../scss/variable";

.upload-button {
   display: flex;
   align-items: center;
   gap: 0.1rem;
   font-size: $sub-heading-font-size !important;
   font-weight: bold !important;
   border-radius: 2px !important;
   background-color: $button-color-red !important;
   padding: .39rem;
   padding-inline: 1rem;
   margin-bottom: 0 !important;
   color: $bg-white-smoke !important;
   margin-left: .5rem;
   min-width: 6rem;
   min-height: 2rem;
   line-height: 1.5;
     
   .upload-button-svg {
    height: 18px;
    width: 18px;
    fill: $bg-white-smoke;
   }
}

.upload-button:hover {
    background-color: $bg-white-smoke !important;
    color: $button-color-red !important;
    border: .5px solid $button-color-red !important;
    cursor: pointer;

    .upload-button-svg {
        fill: $button-color-red;
       }
}

@media (max-width:600px) {
    .upload-button{
        min-width: 3.5rem !important;

    }  
  }