@import "../scss/_variable.scss";

* {
  font-family: sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-default !important;
}

.text-color-green {
  color: $color-green;
}

.bg-white-smoke {
  background-color: $color-white-smoke;
}

.bg-color-primary {
  background-color: $bg-primary;
}

.text-color-primary {
  color: $text-primary-color;
}

.text-true-blue {
  color: $color-true-blue;
}

.text-color-white {
  color: $color-white;
}

.text-color-secondary {
  color: $text-secondary-color;
}

.text-dark-gray {
  color: $color-dark-gray;
}

.bg-color-secondary {
  background-color: $bg-secondary;
}

// Table Styles
.table-header {
  text-align: left;
  background-color: $bg-secondary;
}

/* Font weight */

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/* To cover the pagetitle background color for content details page */
.bgcover-pagetitle {
  margin: 0px -12px;
  background: #f6f6f6;
}

.detailCardList {
  display: inline-table;
}
@media (min-width: 700px) and (max-width: 1500px) {
  .DragContent {
    height: 480px;
  }
}
@media (min-width: 1500px) {
  .DragContent {
    height: 750px;
  }
}
@media (max-width: 700px) {
  .DragContent {
    height: 300px;
  }
}

.link {
  color: #009dda;
}

.bold {
  font-weight: 600;
}

.desktop-only {
  @media (max-width: 992px) {
    display: none;
  }
}
.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

/* Media query */
@media only screen and (max-width: 765px) {
  .responsive {
    display: block;
    position: relative;
    width: 100%;
  }
  .hide {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .responsive {
    display: block;
    grid-column: 1;
    position: relative;
    width: 100%;
    scroll-behavior: auto;
  }
  .hide {
    display: none !important;
  }
}

@media only screen and (max-width: 990px) {
  .hide {
    display: none !important;
  }
}

.primary-button {
  background-color: $primary-button-color !important;
  border-color: $primary-button-color !important;
  font-size: $button-font-size !important;
}

.secondary-button {
  color: $primary-button-color !important;
  border-color: $primary-button-color !important;
}

.redux-table-responsive {
  .table-responsive {
    overflow-x: scroll !important;
  }
}

.desktop-only {
  @media (max-width: 992px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.mobile-only {
  @media (max-width: 992px) {
    display: block;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}

.vh-80 {
  min-height: 82vh;
}

pre {
  white-space: pre-wrap;
}

.btn-gray {
  background-color: $color-input-gray;
}

.cursor-pointer {
  cursor: pointer;
}

.img-15 {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.redux-table-responsive {
  .table-responsive {
    overflow-x: scroll !important;
  }
}

.text-link {
  color: $color-cerulean;
}

.text-underline {
  text-decoration: underline;
}

.underline {
  color: red;
}


.base-color {
  color:$base-color;
}

.correspondence-history {
  font-weight: 800;
  font-size: $heading-font-size;
  line-height: 20px;
  color: $text-secondary-color !important;
}

.drag-content-title {
  font-weight: 400;
  font-size: $sub-heading-font-size;
  line-height: 18px;
}

.contact-information {
  font-weight: 800 !important;
  font-size: $heading-font-size !important;
  line-height: 20px;
  color: $text-secondary-color !important;
}

.section-button {
  margin-top: 2px;
}

.form-control {
  font-size: $font-size-default !important;
}
